<template>
  <div class="about">
    <van-nav-bar title="关于我们" left-arrow @click-left="onClickLeft" />
    <div class="about-logo"></div>
    <div class="about-content">
      我们是由深圳写手智能科技有限公司开发的一款智能内容服务软件，主要面向公职人员、教授、大学生、记者、白领、律师、自由职业者以及智库机构、新媒体等，提供写作、审核、阅读、存储、合规等解决方案，致力提升用户软实力。
    </div>
    <div class="about-agreement">
      <span class="txt">
        <a href="javascript:void(0)" @click.stop="getUserAgree">《用户协议》</a
        >
        <a href="javascript:void(0)" @click.stop="getPrivacyPolicy"
          >《隐私政策》</a
        >
      </span>
    </div>
    <div class="about-footer">
      <p>深圳写手智能科技有限公司</p>
      <p>Shenzhen writer Intelligent Technology Co., Ltd</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      console.log("点击了右边");
    },
    //获取用户协议
    getUserAgree() {
      if (window.android) {
        const data = { action: "UserAgreement" };
        let jsonStr = JSON.stringify(data);
        window.android.postMessage(jsonStr);
      } else if (window.webkit) {
        const data = { action: "UserAgreement" };
        let jsonStr = JSON.stringify(data);
        window.webkit.messageHandlers.sendMessage.postMessage(jsonStr);
      } else {
        console.log("isipc");
      }
    },
    //获取隐私政策
    getPrivacyPolicy() {
      if (window.android) {
        const data = { action: "PrivacyPolicy" };
        let jsonStr = JSON.stringify(data);
        window.android.postMessage(jsonStr);
      } else if (window.webkit) {
        const data = { action: "PrivacyPolicy" };
        let jsonStr = JSON.stringify(data);
        window.webkit.messageHandlers.sendMessage.postMessage(jsonStr);
      } else {
        console.log("isipc");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//改变vantui的默认样式
::v-deep .van-nav-bar__text {
  font-weight: bold;
  font-size: 17px;
  color: #333;
}
::v-deep .van-nav-bar__arrow {
  color: #333;
}
::v-deep .van-nav-bar__title {
  font-weight: bold;
  font-size: 17px;
  color: #333;
}

.about {
  position:relative;
  height:100vh;
  min-height:580px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 28px;
  &-logo {
    margin: 0 auto;
    margin-top: 50px;
    width: 189px;
    height: 51px;
    background-image: url("~@image/logo.png");
    background-size: 189px 51px;
    background-repeat: no-repeat;
  }
  &-content {
    margin: 0 auto;
    margin-top: 50px;
    width: 284px;
    height: 153px;
    text-indent: 2em;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
  }
  &-footer {
    position: absolute;
    bottom: 15px;
    width: 100%;
    p {
      font-size: 10px;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      line-height: 15px;
    }
  }
  &-agreement {
    position:absolute;
    width: 100%;
    bottom:62px;
    text-align: center;
    .txt {
      font-size: 14px;
      color: #999;
      a {
        color: #3783f3;
      }
    }
  }
}
</style>
